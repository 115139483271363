
@import './../../constants/color.scss';

.loginSpinner .MuiDialogContent-root {
    font-size: 24px;
    color: $primaryblack
}

.loginNotification .MuiBackdrop-root {
    background: $primaryblack;
    opacity: 1;
}