@import '../../constants/color.scss';

.nikeTopHeaderContainer {
    background: black;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    align-items: center;
    height: 60px;
}
.nikeHeaderTitle {
    p {
        font-size: 18px;
        color: white;
    }
}
.nikeBottomHeader {
    height: 50px;
    background: $darkBG;
    .nikeNavBar  {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .menuItem {
            font-size: 16px;
            color: white;
            text-align: center;
            margin: 0 10px;
            text-transform: capitalize;
        }
    }
}
.menuItemContainer {
    position: relative;
    .subMenuItemContainer {
        position: absolute;
        background: $darkBG;
        border-radius: 0px 0px 4px 4px;
        z-index: 10;
        color: #fff;
        top: 47px;
        left: 0;
        box-shadow: 0px 18px 50px -10px #00000060;
        right: 0;
        width: 100%;
        text-transform: capitalize;
        .subMenuItem {
            border-top: 1px solid #ffffff40;
        }
    }
}
.userBtnTooltip {
    background-color: $black;
}
.MuiTooltip-tooltip {
    font-size: 30px;
}