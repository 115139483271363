@import '../src/constants/color.scss';

#root>.rootContainer>.rootContainerBody>.rootContainerWrapper,
#root>.rootContainer>.rootContainerBody {
  min-height: 100vh;
}

.rootContainerBody {
  background: #e5e5e5;
}

button.MuiIconButton-root {
  color: $primaryblack;
}

button.MuiIconButton-root:hover {
  background-color: transparent;
}

.po-export-dialog .MuiDialog-paper {
  width: 28%
}

.po-export-dialog {
  .MuiDialogTitle-root {
    padding: 24px 24px 16px 24px;
  }
}

.hidden {
  display: none;
}

.disable-events {
  pointer-events: none;
}

.pageOuterContainer {
  min-height: calc(100vh - 110px);
  background-color: $white;
  margin-top: 110px;
}

.appContainer {
  min-height: calc(100vh - 139px);
}

.MuiCheckbox-root {
  color: $black;
}
.rootContainerHeader {
  position: fixed;
  height: 110px;
  width: 100%;
  z-index: 100;
}
.MuiTooltip-tooltip {
  font-size: 12px;
}