@import url('https://www.nike.com/assets/ncss/3.0/dotcom/desktop/css/ncss.en-us.min.css');

/** HelveticaNeue font */
@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-01.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-Bold-02.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-BoldItalic-04.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-CondensedBlack-10.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-Italic-03.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-Light-08.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-LightItalic-09.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-Medium-11.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-Thin-13.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-UltraLight-06.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('./constants/fonts/HelveticaNeue_Font_Family/HelveticaNeue-UltraLightItalic-07.ttf') format('truetype');
  font-style: italic;
  font-weight: 100;
}

body {
  margin: 0;
  font-family: 'HelveticaNeue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTypography-h5, .MuiInputBase-input, a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,
center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,
hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,
strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {
  font-family: 'HelveticaNeue', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}
